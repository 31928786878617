import { render, staticRenderFns } from "./channel-matching-chart-modal.vue?vue&type=template&id=056092f6&scoped=true&"
import script from "./channel-matching-chart-modal.ts?vue&type=script&lang=ts&"
export * from "./channel-matching-chart-modal.ts?vue&type=script&lang=ts&"
import style0 from "./channel-matching-chart-modal.vue?vue&type=style&index=0&id=056092f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056092f6",
  null
  
)

export default component.exports